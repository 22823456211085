import { ulid } from "ulid";
import { CohortCode } from "./cohort-code";

export class Session {
  generated: Date = new Date();
  sessionUUID: string = ulid();
  deviceUUID: string;
  cohortCode: string;

  constructor(deviceUUID: string) {
    this.deviceUUID = deviceUUID;
    return this;
  }
}
