import { Component, OnInit, Input } from "@angular/core";
import { CodesService } from "../codes.service";

@Component({
  selector: "app-codes-generate",
  templateUrl: "./codes-generate.component.html",
  styleUrls: ["./codes-generate.component.css"]
})
export class CodesGenerateComponent implements OnInit {
  @Input()
  numberOfCodes = 20;
  constructor(private codesService: CodesService) {}

  ngOnInit() {}

  generateCodes() {
    this.codesService.generateCodes(this.numberOfCodes);
  }
}
