import { Component, OnInit } from "@angular/core";
import { CodesService } from "../codes.service";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { CohortCode } from "models/cohort-code";

@Component({
  selector: "app-code-summary",
  templateUrl: "./code-summary.component.html",
  styleUrls: ["./code-summary.component.css"]
})
export class CodeSummaryComponent implements OnInit {
  cohortStats: any;
  constructor(private codesService: CodesService, private logger: NGXLogger) {}

  ngOnInit() {
    this.codesService.getCohortStats().subscribe(results => {
      this.cohortStats = results;
    });
  }
}
