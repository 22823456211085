import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { ResourceComponent } from "./resource/resource.component";
import { SessionGuard } from "./session.guard";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AdminComponent } from './admin/admin/admin.component';
import { AdminAuthGuard } from './admin/admin-auth.guard';

const routes: Routes = [
  { path: "", redirectTo: "/dashboard", pathMatch: "full" },
  { path: "404", component: PageNotFoundComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "login", component: LoginComponent },
  {
    path: "resource/:resourceSlug",
    component: ResourceComponent,
    canActivate: [SessionGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
