import { Component, OnInit } from "@angular/core";
import { ResourceService } from "src/app/services/resource.service";

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.css"]
})
export class NavComponent implements OnInit {
  resources: any;
  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  constructor(private resourceService: ResourceService) {}

  ngOnInit() {
    this.resourceService.getNavResources().subscribe(results => {
      this.resources = results;
    });
  }
}
