import { ulid } from "ulid";
import { CohortCode } from "./cohort-code";

export class Device {
  id: string = ulid();
  deviceUUID: string;
  firstSeen: any = new Date();
  lastSeen: Date = new Date();
  deviceInfo: {} = { info: {}, isMobile: {}, isTablet: {}, isDesktop: {} };
  cohortCodes: {} = {};
}
