import { Upload } from "src/app/models/upload";
import * as firebase from "firebase/app";
import { Observable } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import * as moment from "moment";

export class UploadAdapterTinyMCE {
  private blob;
  private basePath = "/uploads";
  uploads: Observable<Upload[]>;
  private afs: AngularFirestore;
  resourceId: any;

  constructor(blob: any, afs: AngularFirestore, resourceId: string) {
    this.blob = blob;
    this.afs = afs;
    this.resourceId = resourceId;
  }
  public upload(): Promise<any> {
    // "data:image/png;base64,"+ btoa(binaryString)
    return this.readThis(this.blob);
  }
  private blobToFile(theBlob: Blob, filename: string): File {
    const file: any = theBlob;
    file.lastModified = new Date();
    file.name = filename;
    return <File>file;
  }
  readThis(blob: any): Promise<any> {
    const stamp =
      moment()
        .format("YYYYMMDDssmmSS")
        .toString() + "_";
    return new Promise((resolve, reject) => {
      const storageRef = firebase.storage().ref();
      const file = this.blobToFile(blob, blob.filename());
      const upload = new Upload(file);
      const uploadTask = storageRef
        .child("" + this.basePath + "/images/" + stamp + file.name)
        .put(blob.blob(), { customMetadata: { resourceId: this.resourceId } });

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          // upload in progress
          //   upload.progress =
          //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          // upload failed
          console.log("upload error:", error);
          reject("Error: " + error.message);
        },
        () => {
          // upload success
          return uploadTask.snapshot.ref
            .getDownloadURL()
            .then(url => {
              upload.url = url;
              upload.name = stamp + blob.blob().name;
              upload.fileType = blob.blob().type;
              upload.fileLastModified = new Date(blob.lastModified);
              upload.fileSize = blob.blob().size;
              return this.saveFileData(upload);
            })
            .then(f => {
              console.log("upload:", upload, f);
              resolve({ location: upload.url });
            });
        }
      );
    }).catch(err => {
      console.log(err);
    });
  }

  private saveFileData(upload: Upload) {
    return this.afs
      .collection(this.basePath)
      .add(Object.assign({}, upload))
      .then(foo => {})
      .catch(err => {
        console.log("error: ", err);
        throw err;
      });
  }
}
