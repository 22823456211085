import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminDashboardComponent } from "./admin-dashboard/admin-dashboard.component";
import { AdminAuthGuard } from "./admin-auth.guard";
import { AdminComponent } from "./admin/admin.component";
import { ResourceListComponent } from "./resources/resource-list/resource-list.component";
import { CodesComponent } from "./codes/codes.component";
import { ResourceEditComponent } from "./resources/resource-edit/resource-edit.component";
import { ParticipantsDashboardComponent } from "./participants/participants-dashboard/participants-dashboard.component";
import { ParticipantComponent } from "./participants/participant/participant.component";
import { ResourceDashboardComponent } from "./resources/resource-dashboard/resource-dashboard.component";
import { ResourceActivityComponent } from "./resources/resource-activity/resource-activity.component";

const routes: Routes = [
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AdminAuthGuard],
    children: [
      {
        path: "",
        children: [
          { path: "", component: AdminDashboardComponent },
          {
            path: "resources",
            children: [
              { path: "", component: ResourceDashboardComponent },
              { path: "activity", component: ResourceDashboardComponent },
              { path: "editor", component: ResourceListComponent },
              { path: "editor/:resourceId", component: ResourceEditComponent },
              { path: "delete/:resourceId", component: ResourceListComponent },
              {
                path: "deleteUndo/:resourceId",
                component: ResourceListComponent
              },
              { path: "new/:type", component: ResourceEditComponent },
              {
                path: "activity/:resourceId",
                component: ResourceActivityComponent
              }
            ]
          },
          { path: "codes", component: CodesComponent },
          { path: "codes/:showAll", component: CodesComponent },
          {
            path: "participants",
            children: [
              { path: "", component: ParticipantsDashboardComponent },
              { path: "device/:deviceUUID", component: ParticipantComponent }
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {}
