import { Routes, RouterModule } from "@angular/router";
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
    {
        path: "**",
        component: PageNotFoundComponent
      }
];
@NgModule({
    declarations: [PageNotFoundComponent],
    imports: [
    BrowserModule,
    FormsModule,
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class WildcardRoutingModule {}