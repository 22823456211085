import { ulid } from "ulid";

export class Resource {
  id: string = ulid();
  dateCreated: Date = new Date();
  dateUpdated: Date = new Date();
  type: string;
  ref: string;
  content: string;
  order = 0;
  active: boolean;
  slug: string;
  name: string;
  attachment; // TODO: determine how to type binary content
  protected = false;
  hits = [];
  showInNavigation = true;
  resourceStyles = "";
  resourceScripts = "";
  deleted = false;
}
