import { BrowserModule } from "@angular/platform-browser";
import { NgModule, isDevMode } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "../environments/environment";
import { FormsModule } from "@angular/forms";
import { DeviceDetectorModule } from "ngx-device-detector";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { MarkdownModule } from "ngx-markdown";
import { MomentModule } from "ngx-moment";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { AdminModule } from "./admin/admin.module";
import { UiModule } from "./ui/ui.module";
import { ResourceComponent } from "./resource/resource.component";
import { EditorModule } from "@tinymce/tinymce-angular";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorInterceptor } from "./ErrorInterceptor";
import { WildcardRoutingModule } from "./wildcard-routing.module";
import { SafePipe } from "./safe.pipe";
import { CookieModule } from "ngx-cookie";
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    ResourceComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AppRoutingModule,
    MomentModule,
    AdminModule,
    HttpClientModule,
    UiModule,
    FormsModule,
    EditorModule,
    DeviceDetectorModule.forRoot(),
    MarkdownModule.forRoot(),
    LoggerModule.forRoot({
      serverLoggingUrl: "/api/logs",
      level: environment.production
        ? NgxLoggerLevel.WARN
        : NgxLoggerLevel.DEBUG,
      serverLogLevel: environment.production
        ? NgxLoggerLevel.OFF
        : NgxLoggerLevel.ERROR
    }),
    WildcardRoutingModule,
    CookieModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
