import { formatDate } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { firestore } from "firebase/app";
import Timestamp = firestore.Timestamp;

@Pipe({
  name: "deviceInfo"
})
export class DeviceInfoPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(deviceInfo: object, format?: string): string {
    if (deviceInfo !== undefined && deviceInfo !== null) {
      let clientFormat = "";
      deviceInfo["isDesktop"]
        ? (clientFormat = "desktop")
        : deviceInfo["isTablet"]
        ? (clientFormat = "tablet")
        : deviceInfo["isMobile"]
        ? (clientFormat = "mobile")
        : (clientFormat = "unknown");
      return (
        clientFormat +
        " " +
        deviceInfo["info"]["os"] +
        " " +
        deviceInfo["info"]["browser"]
      );
    } else {
      return "";
    }
  }
}
