import { Injectable, Injector } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { AuthService } from "./services/auth.service";
import { map, catchError, tap, finalize } from "rxjs/operators";
import { NGXLogger } from "ngx-logger";
class HttpError {
  static BadRequest = 400;
  static Unauthorized = 401;
  static Forbidden = 403;
  static NotFound = 404;
  static TimeOut = 408;
  static Conflict = 409;
  static InternalServerError = 500;
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  // Regular dep. injection doesn't work in HttpInterceptor due to a framework issue (as of angular@5.2.9),
  // use Injector directly (don't forget to add @Injectable() decorator to class).
  constructor(
    private _injector: Injector,
    private router: Router,
    private logger: NGXLogger
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const started = Date.now();
    let ok: string;
    this.logger.debug("in interceptor");
    return next.handle(req).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        event => (ok = event instanceof HttpResponse ? "succeeded" : "")
      ),
      catchError((err: HttpErrorResponse) => {
        this.logger.debug("err:", err.message);
        this.logger.debug("err.status:", err.status);
        return throwError(err);
      }),
      // Log when response observable either completes or errors
      finalize(() => {
        const elapsed = Date.now() - started;
        const msg = `${req.method} "${req.urlWithParams}"
             ${ok} in ${elapsed} ms.`;
      })
    );
  }
}
