import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  message: string;

  constructor(public auth: AuthService) { }

  ngOnInit() {
  }
  setMessage() {
    this.message = 'Logged ' + (this.auth.isLoggedIn ? 'in' : 'out');
  }
  logout() {
    this.auth.logout();
    this.setMessage();
  }
}
