import { Component, OnInit } from "@angular/core";
import { ResourceService } from "src/app/services/resource.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { Resource } from "models/resource";
import { SlugifyPipe } from "src/app/shared/slugify.pipe";

import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { UploadAdapter } from "./UploadAdapter";
import { AngularFirestore } from "@angular/fire/firestore";
import { UploadAdapterTinyMCE } from "./UploadAdapterTinyMCE";
import * as moment from "moment";
import * as firebase from "firebase/app";
import { Upload } from "src/app/models/upload";

@Component({
  selector: "app-resource-edit",
  templateUrl: "./resource-edit.component.html",
  styleUrls: ["./resource-edit.component.css"]
})
export class ResourceEditComponent implements OnInit {
  public Editor = ClassicEditor;
  private basePath = "/uploads";

  resourceId: string;
  resource: any;
  mode = "editor";
  options = {
    hideIcons: ["Image", "Code"] // default is empty, the value is case-sensitive
  };
  resourceType: string;
  constructor(
    private resourceService: ResourceService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: NGXLogger,
    private slugifyPipe: SlugifyPipe,
    private afs: AngularFirestore
  ) {}

  ngOnInit() {
    this.resourceId = this.route.snapshot.paramMap.get("resourceId");
    if (this.resourceId) {
      this.resourceService.getResource(this.resourceId).then(resource => {
        this.resource = resource;
        this.logger.debug("Resource found:", this.resource);
      });
    } else {
      this.resourceType = this.route.snapshot.paramMap.get("type");
      if (this.resourceType === "page") {
        this.resourceService.getNewOrder().then(order => {
          this.resource = new Resource();
          this.resource.order = order;
          this.resource.type = "PAGE";
        });
      }
    }
  }

  tinyUploadHandler = (blobInfo: any, success: any, failure: any) => {
    console.log("blobInfo", blobInfo);
    console.log("blobInfo.filename", blobInfo.filename());
    const uploader = new UploadAdapterTinyMCE(
      blobInfo,
      this.afs,
      this.resourceId
    );
    return uploader
      .upload()
      .then(data => {
        console.log("data", data);
        success(data.location);
      })
      .catch(err => {
        failure(err.message);
      });
  }

  onReady(eventData) {
    eventData.plugins.get("FileRepository").createUploadAdapter = loader => {
      return new UploadAdapter(loader, this.afs, this.resourceId);
    };
  }

  save(resource: Resource) {
    this.logger.debug("Resource", resource);
    resource.dateUpdated = new Date();
    this.resourceService.saveResource(resource).then(message => {
      this.logger.debug(message);
      this.router.navigate(["/admin/resources/editor"]);
    });
  }
  slugify() {
    if (!this.resource.protected) {
      const resourceName = this.resource.name;
      this.resource.slug = this.slugifyPipe.transform(resourceName);
    }
  }
}
