import { Component, OnInit } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { NGXLogger } from "ngx-logger";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  message: string;
  invalidAccount: any;

  constructor(
    public auth: AuthService,
    public router: Router,
    private logger: NGXLogger
  ) {
    this.setMessage();
  }

  setMessage() {
    this.message = "Logged " + (this.auth.isLoggedIn ? "in" : "out");
  }

  ngOnInit() {
    this.auth.getInvalidAccount().subscribe(f => {
      console.log("invalidAccount", f);
      this.invalidAccount = f;
    });
  }
  login() {
    this.message = "Trying to log in ...";

    this.auth.googleLogin().then(foo => {
      this.logger.debug("foo:", foo);
      this.router.navigate(["/admin"]);
    });
  }

  logout() {
    this.auth.logout();
    this.setMessage();
  }
}
