import { Injectable } from "@angular/core";
import { DeviceUUID } from "device-uuid";
import {
  AngularFirestore,
  AngularFirestoreDocument
} from "@angular/fire/firestore";
import { Participant } from "models/participant";
import { Observable, Timestamp } from "rxjs";
import { ulid } from "ulid";
import { CohortCode } from "models/cohort-code";
import { Device } from "models/device";

import { DeviceDetectorService } from "ngx-device-detector";
import { Session } from "models/session";
import { NGXLogger } from "ngx-logger";
import { SessionLoggingService } from "./session-logging.service";
import { DeviceService } from "./device.service";
import * as moment from "moment";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(
    private afs: AngularFirestore,
    private deviceDetectorService: DeviceDetectorService,
    private logger: NGXLogger,
    private sessionLogger: SessionLoggingService,
    private deviceService: DeviceService
  ) {}

  getDeviceUUID() {
    return this.deviceService.getDeviceUUID().then(result => {
      return result;
    });
  }
  validateCohortCode(cohortCode: string) {
    let cohortDoc: CohortCode;
    return new Promise((resolve, reject) => {
      this.afs
        .collection<CohortCode>("cohortCodes")
        .doc(cohortCode.toString())
        .valueChanges()
        .subscribe(chdoc => {
          if (chdoc) {
            cohortDoc = chdoc as CohortCode;
            this.logger.debug("code validation", cohortDoc);
            if ((cohortDoc.claimedDate = chdoc["claimedDate"])) {
              cohortDoc.claimedDate = chdoc["claimedDate"].toDate();
            } else {
              cohortDoc.claimedDate = new Date();
              this.recordCohortCodeClaimed(cohortDoc);
            }
            cohortDoc.generatedDate = chdoc["generatedDate"].toDate();
            this.logger.debug("cohortDoc debug", cohortDoc);
            resolve({ cohortCode: cohortDoc });
          } else {
            reject(
              "Code not found, please check your code and try again, or continue as a guest"
            );
          }
        });
    });
  }
  recordCohortCodeClaimed(cohortCode: CohortCode) {
    this.logger.debug("Claiming code for the first time", cohortCode);
    const dayCollectionRef = this.afs.firestore
      .collection("stats")
      .doc("enrollmentStats")
      .collection("dates");
    const hourCollectionRef = this.afs.firestore
      .collection("stats")
      .doc("enrollmentStats")
      .collection("hours");
    const dateNow = moment().format("YYYYMMDD");
    const hourNow = moment().format("HH");
    const dateDocRef = dayCollectionRef.doc(dateNow);
    const hourDocRef = hourCollectionRef.doc(hourNow);
    const data = { timestamp: new Date() };
    data["cohortCodes"] = {};
    data["cohortCodes"][cohortCode.cohortCode] = cohortCode.cohortCode;
    dateDocRef.set(data, { merge: true }).then(() => {
      hourDocRef.set(data, { merge: true });
    });
  }
  registerDevice(deviceUUID: string, session: Session, guest: boolean = true) {
    const data = {};
    this.logger.debug("Registering Device");
    return this.deviceService.getDevice(deviceUUID).then(deviceResult => {
      const device = deviceResult as Device;

      if (guest) {
        this.logger.debug(
          "UserService: registerDevice: Guest: deviceResult",
          device
        );
        // if firstseen date and hour the same as now - assume this is the first we're seeing this device as a guest and log the 'enrolment'
        if (device.firstSeen) {
          const firstSeenTimestamp = device.firstSeen;
          const now = moment();
          const firstSeen = moment(firstSeenTimestamp.toDate());
          this.logger.debug(
            "UserService: registerDevice: Guest: deviceResult",
            now,
            firstSeen
          );
          if (now.dayOfYear() === firstSeen.dayOfYear()) {
            if (now.hour() === firstSeen.hour()) {
              // assume this is the first time we've seen this guest
              const dayCollectionRef = this.afs.firestore
                .collection("stats")
                .doc("enrollmentStats")
                .collection("dates");
              const hourCollectionRef = this.afs.firestore
                .collection("stats")
                .doc("enrollmentStats")
                .collection("hours");
              const dateNow = moment().format("YYYYMMDD");
              const hourNow = moment().format("HH");
              const dateDocRef = dayCollectionRef.doc(dateNow);
              const hourDocRef = hourCollectionRef.doc(hourNow);
              const enrolmentData = { timestamp: new Date() };
              enrolmentData["devices"] = {};
              enrolmentData["devices"][deviceUUID] = deviceUUID;
              this.logger.debug(
                "DeviceService: enrolment stats",
                enrolmentData,
                device
              );
              dateDocRef
                .set(enrolmentData, { merge: true })
                .then(() => {
                  return hourDocRef.set(enrolmentData, { merge: true });
                })
                .then(() => {});
            }
          }
        }
      }

      this.logger.debug("Device info loaded", device);
      device.deviceInfo = {};
      data["existingDevice"] = true;
      if (guest) {
        data["action"] = "RETURNING_AS_GUEST";
      } else {
        data["action"] = "RETURNING_AS_COHORT";
      }
      data["timestamp"] = new Date();
      return new Promise((resolve, reject) => {
        device.lastSeen = new Date();
        if (session && session.cohortCode) {
          if (!device.cohortCodes) {
            device.cohortCodes = {};
          }
          device.cohortCodes[session.cohortCode] = session.cohortCode;
        } else {
          session = new Session(deviceUUID);
          session.cohortCode = "";
        }
        device.deviceInfo["info"] = this.deviceDetectorService.getDeviceInfo();
        device.deviceInfo["isMobile"] = this.deviceDetectorService.isMobile();
        device.deviceInfo["isTablet"] = this.deviceDetectorService.isTablet();
        device.deviceInfo["isDesktop"] = this.deviceDetectorService.isDesktop();
        session.deviceUUID = deviceUUID;
        // device.deviceInfo = device.deviceInfo;
        this.afs
          .collection("devices")
          .doc(deviceUUID)
          .collection("sessions")
          .doc(session.sessionUUID)
          .set(
            Object.assign(
              {
                cohortCode: session.cohortCode,
                cohortCodes: device.cohortCodes,
                deviceUUID: deviceUUID,
                timestamp: moment().toDate()
              },
              session
            ),
            {
              merge: true
            }
          )
          // .then(() => {
          //   return this.afs.collection("devices").doc(deviceUUID)
          // })
          // todo claim the cohort code for this device
          .then(() => {
            this.logger.debug("Before error?:deviceUUID:", deviceUUID);

            if (session.cohortCode) {
              return this.afs
                .collection("cohortCodes")
                .doc(session.cohortCode.toString())
                .set(
                  Object.assign({
                    claimed: true,
                    claimedDate: new Date(),
                    deviceUUID: deviceUUID
                  }),
                  { merge: true }
                );
            } else {
              return {};
            }
          })
          .then(() => {
            this.logger.debug(
              "Registering new session for device",
              deviceUUID,
              " session:",
              session
            );
            resolve(
              this.sessionLogger.actionLog(
                deviceUUID,
                session.sessionUUID,
                "ENTERING_HOME",
                { data: data },
                session
              )
            );
          })
          .catch(err => {
            reject(err);
          });
      });
    });
  }
  // getUserAuth(uuid: string){
  //   return new Promise((resolve, reject)=>{
  //     this.logger.debug()
  //   })
  //   let participantDoc: AngularFirestoreDocument<Participant>;
  //   participantDoc = this.afs.doc<Participant>('participants/' + uuid);
  //   participantDoc.get().toPromise().then((partDoc) => {
  //     if (partDoc.exists){
  //       const theParticipant = new Participant();
  //       theParticipant.cohortCode = partDoc.data().cohortCode;
  //       theParticipant.deviceUUID = uuid;
  //       theParticipant.startDate = partDoc.data().startDate.toDate();
  //       theParticipant.lastAccessedDate = new Date();
  //       this.logger.debug(theParticipant);
  //       return this.afs.collection('participants').doc(uuid).set(Object.assign({}, theParticipant), {merge: true});
  //     } else {
  //       //     // create a participant record
  //           return this.afs.collection('participants').doc(uuid)
  //             .set({id: ulid(), startDate: new Date(), lastAccessedDate: new Date(), cohortCode: '', deviceUUID: uuid}, {merge: true});
  //     }
  //   });
}
