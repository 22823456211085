import { Component, OnInit } from "@angular/core";
import { ResourceService } from "src/app/services/resource.service";
import { Observable } from "rxjs";
import { Resource } from "models/resource";
import { NGXLogger } from "ngx-logger";
import {
  AngularFirestoreCollection,
  AngularFirestore
} from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { StatsService } from "../../stats/stats.service";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { routerNgProbeToken } from "@angular/router/src/router_module";

@Component({
  selector: "app-resource-list",
  templateUrl: "./resource-list.component.html",
  styleUrls: ["./resource-list.component.css"]
})
export class ResourceListComponent implements OnInit {
  resources: any;
  searchFilter = "";
  deletedName: string;
  deletedId: string;
  resourceId: string;

  constructor(
    private resourceService: ResourceService,
    private statsService: StatsService,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private router: Router
  ) {}

  ngOnInit() {
    this.resourceId = this.route.snapshot.paramMap.get("resourceId");
    if (
      this.resourceId &&
      this.route.routeConfig.path === "delete/:resourceId"
    ) {
      this.resourceService
        .deleteResource(this.resourceId)
        .then((resource: Resource) => {
          this.deletedName = resource.name;
          this.deletedId = resource.id;
        });
    }
    if (
      this.resourceId &&
      this.route.routeConfig.path === "deleteUndo/:resourceId"
    ) {
      this.resourceService
        .undoDelete(this.resourceId)
        .then((resource: Resource) => {
          this.deletedId = null;
          this.deletedName = null;
        });
    }
    this.resourceService
      .getResources(this.searchFilter)
      .subscribe(resources => {
        this.resources = resources;
      });
    // this.statsService
    //   .getResourceList(this.searchFilter)
    //   .subscribe(resources => {
    //     console.log("resources", resources);
    //     this.resources = resources;
    //   });
  }

  updateOrder(resource: Resource, event: any) {
    // resource.order = event.target.value;
    // this.resourceService.saveResource(resource);
  }

  getResourceList(event: any) {
    if (this.searchFilter.length >= 3) {
      this.resourceService
        .getResources(this.searchFilter)
        .subscribe(resources => {
          this.logger.debug("resource:", resources);
          this.resources = resources;
        });
    } else if (this.searchFilter.length === 0) {
      this.resourceService
        .getResources(this.searchFilter)
        .subscribe(resources => {
          this.logger.debug("resource:", resources);
          this.resources = resources;
        });
    }
  }

  dropPage(event: CdkDragDrop<Resource>) {
    console.log("event:", event, event.previousIndex, event.currentIndex);
    const resource = event.item.data as Resource;
    console.log("resource", resource);
    resource.order = event.currentIndex;
    this.resourceService.updateOrder(
      resource,
      event.previousIndex,
      event.currentIndex
    );
  }
  rebasePages() {
    this.resourceService.rebasePageOrder();
  }
  editResource(resource: Resource) {
    this.router.navigate(["/admin/resources/" + resource.id]);
  }
  newPage() {
    this.router.navigate(["/admin/resources/new/page"]);
  }
}
