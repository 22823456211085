import { Component, OnInit } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { StatsService } from "../../stats/stats.service";
import { ResourceService } from "src/app/services/resource.service";
import { resource } from "selenium-webdriver/http";

@Component({
  selector: "app-resource-dashboard",
  templateUrl: "./resource-dashboard.component.html",
  styleUrls: ["./resource-dashboard.component.css"]
})
export class ResourceDashboardComponent implements OnInit {
  resources: any;
  searchFilter = "";
  constructor(
    private logger: NGXLogger,
    private statsService: StatsService,
    private resourceService: ResourceService
  ) {}

  ngOnInit() {
    this.getResourceList({});
  }

  getResourceList(event: any) {
    this.resourceService.getResources(this.searchFilter).subscribe(results => {
      this.resources = results;
    });
  }
}
