import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit
} from "@angular/core";
import { DeviceService } from "src/app/services/device.service";
import { NGXLogger } from "ngx-logger";
import { SessionLoggingService } from "src/app/services/session-logging.service";
import { StatsService } from "../../stats/stats.service";
import { chart, SeriesOptionsType } from "highcharts";
import * as Highcharts from "highcharts/highcharts";
import * as moment from "moment";
import { setTimeout } from "timers";

@Component({
  selector: "app-participant-summary",
  templateUrl: "./participant-summary.component.html",
  styleUrls: ["./participant-summary.component.css"]
})
export class ParticipantSummaryComponent implements OnInit, AfterViewInit {
  @ViewChild("chartTarget") chartTarget: ElementRef;
  // chart: Highcharts.ChartObject;
  durationInDays = 30;
  deviceCount = 0;
  cohortCount = 0;
  nonCohortCount = 0;
  cohortSessionCount = 0;
  nonCohortSessionCount = 0;
  enrolmentsOverTime = {};
  cohortSeriesData = [];
  nonCohortSeriesData = [];
  seriesCategories = [];
  options: Highcharts.Options = {
    chart: {
      type: "column",
      height: 300,
      margin: [30, 20, 120, 45]
    },
    title: {
      text: ""
    },
    plotOptions: {
      series: {
        threshold: 0.2
      },
      column: {
        stacking: "normal",
        dataLabels: {
          format: "{point.y}",
          enabled: true,
          color: "black"
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: "ENROLMENTS"
      },
      lineColor: "#D9D9D9",
      lineWidth: 3,
      tickInterval: 2
    },
    xAxis: {
      // categories: this.seriesCategories
      type: "category",
      labels: {
        rotation: 45
      },
      lineColor: "#D9D9D9",
      lineWidth: 3,
      tickLength: 0
    }
  };
  chart: Highcharts.Chart;

  constructor(private statsService: StatsService, private logger: NGXLogger) {}
  ngAfterViewInit() {
    this.chart = Highcharts.chart(this.chartTarget.nativeElement, this.options);
    this.chart.addSeries({
      name: "Cohort",
      data: this.cohortSeriesData,
      color: "#1A3256"
    } as SeriesOptionsType);
    this.chart.addSeries({
      name: "Non-cohort",
      data: this.nonCohortSeriesData,
      color: "#00ADA8"
    } as SeriesOptionsType);
    this.updateStats();
    window.setTimeout(() => {
      this.updateChart();
    }, 3000);
  }
  updateChart() {
    this.chart.series[0].setData(this.cohortSeriesData, true);
    this.chart.series[1].setData(this.nonCohortSeriesData, true);
  }
  ngOnInit() {}
  updateStats() {
    this.statsService.getCohortCount(this.durationInDays).subscribe(result => {
      this.cohortCount = Object.keys(result["cohortLog"]).length;
      this.deviceCount = result["deviceTotal"];
    });
    this.statsService
      .getCohortSessionCount(this.durationInDays)
      .subscribe(result => {
        this.cohortSessionCount = result.length;
      });
    this.statsService
      .getNonCohortSessionCount(this.durationInDays)
      .subscribe(result => {
        this.nonCohortSessionCount = result.length;
      });
    this.statsService
      .getEnrolmentData(this.durationInDays)
      .subscribe(result => {
        this.nonCohortSeriesData = [];
        this.cohortSeriesData = [];
        this.seriesCategories = [];
        result.forEach(date => {
          const dated = moment(date.timestamp.toDate()).format("DD/MM/YYYY");
          // this.seriesCategories.push(dated);
          if (date.cohortCodes) {
            this.cohortSeriesData.push([
              dated,
              Object.keys(date.cohortCodes).length
            ]);
          } else {
            this.cohortSeriesData.push([dated, 0]);
          }
          if (date.devices) {
            this.nonCohortSeriesData.push([
              dated,
              Object.keys(date.devices).length
            ]);
          } else {
            this.nonCohortSeriesData.push([dated, 0]);
          }
        });

        this.chart.series[0].setData(this.cohortSeriesData);
        this.chart.series[1].setData(this.nonCohortSeriesData, true);
      });
  }
}
