export class Upload {
  $key: string;
  fileType: string;
  fileLastModified: Date;
  fileSize: number;
  name: string;
  url: string;
  progress: number;
  dateCreated: Date = new Date();

  constructor(file: File) {}
}

/*
lastModified: 1475967424000
lastModifiedDate: Sun Oct 09 2016 09:57:04 GMT+1100 (Australian Eastern Daylight Time) {}
name: "20161009_095704.jpg"
size: 6125896
type: "image/jpeg"
webkitRelativePath: ""
*/
