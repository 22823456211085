import { Component, OnInit } from "@angular/core";
import { DeviceUUID } from "device-uuid";
import { UserService } from "../services/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Session } from "models/session";
import { NGXLogger } from "ngx-logger";
import { SessionLoggingService } from "../services/session-logging.service";
import { DeviceService } from "../services/device.service";
import { CohortCode } from "models/cohort-code";
import { Device } from "models/device";
import * as moment from "moment";
import { DeviceDetectorService } from "ngx-device-detector";
import { CookieService } from "ngx-cookie";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"]
})
export class DashboardComponent implements OnInit {
  cohortCode: string;
  deviceUUID: string;
  message: string;
  processing = false;
  session: Session;

  componentAction = "LANDING_PAGE";
  returnUrl: string;
  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private sessionLogger: SessionLoggingService,
    private deviceService: DeviceService,
    private deviceDetectorService: DeviceDetectorService,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.get("return")) {
      this.returnUrl = this.route.snapshot.queryParamMap.get("return");
    } else {
      this.returnUrl = null;
    }
    this.cohortCode = "";
    const dateNow = new Date();
    const loggingData = {};
    const hitData = {};
    loggingData["timestamp"] = dateNow;
    hitData["timestamp"] = dateNow;
    // loggingData["action"] = this.componentAction;
    loggingData["deviceInfo"] = {};

    let theDevice = new Device();

    const localStoredDeviceUUID = localStorage.getItem("help-deviceUUID");

    const sessionUUID = sessionStorage.getItem("help-session-uuid");
    const sessionGenerated = moment(
      sessionStorage.getItem("help-session-generated")
    ).toDate();
    const sessionDeviceUUID = sessionStorage.getItem("help-session-deviceUUID");
    const sessionCohortCode = sessionStorage.getItem("help-session-cohortCode");
    const cookieCohortCode = this.cookieService.get("help-cohortCode");
    if (sessionCohortCode) {
      this.cohortCode = sessionCohortCode;
    } else if (cookieCohortCode) {
      this.cohortCode = cookieCohortCode;
    }

    let newDevice = true;
    return this.userService
      .getDeviceUUID()
      .then(result => {
        if (localStoredDeviceUUID) {
          this.logger.debug("Found local deviceUUID", localStoredDeviceUUID);
          loggingData["existingLocalDeviceUUID"] = true;
          this.deviceUUID = localStoredDeviceUUID;
        } else {
          this.logger.debug("DashboardComponent: No local deviceUUID found");
          loggingData["existingLocalDeviceUUID"] = false;
          this.deviceUUID = result.toString();
          this.logger.debug(
            "DashboardComponent: New deviceUUID generated:",
            this.deviceUUID
          );
        }
        return this.deviceService.getDevice(this.deviceUUID);
      })
      .then(deviceRecord => {
        this.logger.debug("Device record found", deviceRecord);
        newDevice = false;
        theDevice = deviceRecord as Device;
        theDevice.deviceUUID = this.deviceUUID;
        if (!theDevice.cohortCodes) {
          theDevice.cohortCodes = {};
        }
        if (this.cohortCode && !theDevice.cohortCodes[this.cohortCode]) {
          this.logger.debug(
            "The cohort code does not exist in the device record, adding it"
          );
          theDevice.cohortCodes[this.cohortCode] = this.cohortCode;
          this.logger.debug("theDevice.cohortCodes", theDevice.cohortCodes);
        }
        if (Object.keys(theDevice.cohortCodes).length > 0) {
          this.logger.debug(
            "The device record has at least one cohort code registered, using it"
          );
          this.cohortCode = Object.keys(theDevice.cohortCodes)[0];
        }
        localStorage.setItem("help-deviceUUID", this.deviceUUID);
        this.logger.debug("Device uuid", this.deviceUUID);
        // TODO: save information about a new device
        if (!theDevice.deviceInfo) {
          loggingData["deviceInfo"] = {};
        }
        loggingData["deviceInfo"][
          "info"
        ] = this.deviceDetectorService.getDeviceInfo();
        loggingData["deviceInfo"][
          "isMobile"
        ] = this.deviceDetectorService.isMobile();
        loggingData["deviceInfo"][
          "isTablet"
        ] = this.deviceDetectorService.isTablet();
        loggingData["deviceInfo"][
          "isDesktop"
        ] = this.deviceDetectorService.isDesktop();

        this.deviceService.updateDevice(
          Object.assign({}, theDevice),
          loggingData["deviceInfo"],
          sessionCohortCode,
          newDevice
        );

        if (sessionUUID && sessionDeviceUUID === this.deviceUUID) {
          this.session = Object.assign({
            generated: sessionGenerated,
            sessionUUID: sessionUUID,
            deviceUUID: sessionDeviceUUID
          }) as Session;
          this.logger.debug("Existing session found", this.session);
        } else {
          this.session = new Session(this.deviceUUID);
          this.logger.debug("New session created", this.session);
          // TODO: log this so we can track where/when they started their session
        }

        sessionStorage.setItem("help-session-uuid", this.session.sessionUUID);
        sessionStorage.setItem(
          "help-session-generated",
          this.session.generated.toISOString()
        );
        sessionStorage.setItem(
          "help-session-deviceUUID",
          this.session.deviceUUID
        );
        this.sessionLogger.actionLog(
          this.deviceUUID,
          this.session.sessionUUID,
          this.componentAction,
          loggingData,
          this.session,
          sessionCohortCode,
          "",
          "",
          hitData
        );
      });
  }

  checkCode() {
    this.processing = true;
    this.userService
      .validateCohortCode(this.cohortCode.toString())
      .then(result => {
        this.logger.debug("result", result);
        this.session.cohortCode = this.cohortCode;
        sessionStorage.setItem(
          "help-session-cohortCode",
          this.session.cohortCode
        );
        this.cookieService.put("help-cohortCode", this.session.cohortCode);
        this.userService
          .registerDevice(this.deviceUUID, this.session, false)
          .then(registration => {
            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigate(["/resource/home"]);
            }
          });
      })
      .catch(err => {
        this.logger.debug("Invalid code:", this.cohortCode, " : ", err);
        this.message = err;
        this.processing = false;
      });
  }
  guestLogin() {
    this.logger.debug("Attempting Guest Login", this.session);
    this.processing = true;
    this.userService
      .registerDevice(this.deviceUUID, this.session)
      .then(registration => {
        this.logger.debug(
          "DashboardComponent: Guest Login: Registration",
          registration
        );

        if (this.returnUrl) {
          this.router.navigate([this.returnUrl]);
        } else {
          this.router.navigate(["/resource/home"]);
        }
      })
      .catch(err => {
        this.message = err;
        this.processing = false;
      });
  }
}
