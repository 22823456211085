import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UiModule } from "src/app/ui/ui.module";
import { CodesComponent } from "./codes.component";
import { CodesGenerateComponent } from "./codes-generate/codes-generate.component";
import { CodesListComponent } from "./codes-list/codes-list.component";
import { CodeSummaryComponent } from "./code-summary/code-summary.component";

@NgModule({
  imports: [CommonModule, UiModule],
  declarations: [
    CodesComponent,
    CodesGenerateComponent,
    CodesListComponent,
    CodeSummaryComponent
  ],
  exports: [CodeSummaryComponent]
})
export class CodesModule {}
