import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ParticipantStat } from "models/participant-stat";
import { DeviceService } from "src/app/services/device.service";
import { NGXLogger } from "ngx-logger";
import { StatsService } from "../../stats/stats.service";

@Component({
  selector: "app-participants-dashboard",
  templateUrl: "./participants-dashboard.component.html",
  styleUrls: ["./participants-dashboard.component.css"]
})
export class ParticipantsDashboardComponent implements OnInit {
  constructor(private statsService: StatsService, private logger: NGXLogger) {}
  participants = [];
  searchCode: "";
  cohortOnly = false;
  nonCohortOnly = false;

  ngOnInit() {
    this.getParticipants();
  }
  getParticipants() {
    this.statsService
      .getParticipants(this.searchCode, this.cohortOnly, this.nonCohortOnly)
      .subscribe(participants => {
        this.participants = participants;
      });
  }
  cohortOnlyToggle() {
    this.nonCohortOnly = false;
    this.cohortOnly === true
      ? (this.cohortOnly = false)
      : (this.cohortOnly = true);
    this.getParticipants();
  }
  nonCohortOnlyToggle() {
    this.cohortOnly = false;
    this.nonCohortOnly === true
      ? (this.nonCohortOnly = false)
      : (this.nonCohortOnly = true);
    this.getParticipants();
  }
  filterParticipants(event: any) {
    if (this.searchCode.length >= 3) {
      this.statsService
        .getParticipants(this.searchCode)
        .subscribe(participants => {
          this.participants = participants;
        });
    }
  }

  trackByFn(idx, item) {
    return item.deviceUUID;
  }
}
