import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { NGXLogger } from "ngx-logger";

@Injectable({
  providedIn: "root"
})
export class SessionGuard implements CanActivate {
  constructor(private logger: NGXLogger, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.logger.debug("SessionGuard#canActivate called", next, state);
    const localStoredDeviceUUID = localStorage.getItem("help-deviceUUID");
    const sessionDeviceUUID = sessionStorage.getItem("help-session-deviceUUID");

    if (localStoredDeviceUUID || sessionDeviceUUID) {
      return true;
    } else {
      this.router.navigate(["/dashboard"], {
        queryParams: { return: state.url }
      });
      return false;
    }
  }
}
