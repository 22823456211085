import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { StatsService } from "../../stats/stats.service";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
  selector: "app-participant",
  templateUrl: "./participant.component.html",
  styleUrls: ["./participant.component.css"]
})
export class ParticipantComponent implements OnInit {
  deviceUUID: string;
  participantRecord: any;
  participantRecordTimeline: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private logger: NGXLogger,
    private statsService: StatsService
  ) {}

  ngOnInit() {
    this.deviceUUID = this.route.snapshot.paramMap.get("deviceUUID");
    if (this.deviceUUID) {
      this.statsService.getParticipant(this.deviceUUID).subscribe(record => {
        this.participantRecord = record;
        this.statsService.getPageViewsInTimeline(this.deviceUUID).then(data => {
          this.participantRecordTimeline = data;
        });
      });
    }
  }
  trackByFn(idx, item) {
    return item.actionItemDocID;
  }
  trackByFnTimeline(idx, item) {
    return item.key;
  }
}
