import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { NGXLogger } from "ngx-logger";
import { CohortCode } from "models/cohort-code";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CodesService {
  allocatedCodes: Observable<CohortCode[]>;
  openCodes: Observable<CohortCode[]>;
  claimedCodes: Observable<CohortCode[]>;
  cohortStats: Observable<any>;
  now: Date;
  constructor(private afs: AngularFirestore, private logger: NGXLogger) {
    this.now = new Date();
    this.allocatedCodes = this.getAllocatedCodes();
    this.openCodes = this.getOpenCodes();
    this.claimedCodes = this.getClaimedCodes();
  }
  getCohortStats() {
    return this.afs
      .collection("stats")
      .doc("cohortStats")
      .valueChanges();
  }
  allocateCode(cohortCode: CohortCode): any {
    this.afs
      .collection("cohortCodes")
      .doc(cohortCode.cohortCode.toString())
      .update({ allocatedDate: new Date(), allocated: true });
  }
  getAllocatedCodes() {
    return this.afs
      .collection<CohortCode>("cohortCodes", ref =>
        ref
          .where("allocated", "==", true)
          .where("claimed", "==", false)
          .orderBy("generatedDate", "asc")
      )
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            const chDoc = a.payload.doc.data() as CohortCode;
            chDoc["selected"] = false;
            return chDoc;
          });
        })
      );
  }
  getOpenCodes(limit: number = 10) {
    if (limit < 0) {
      limit = 10000;
    }
    return this.afs
      .collection<CohortCode>("cohortCodes", ref =>
        ref
          .where("claimed", "==", false)
          .where("allocated", "==", false)
          .orderBy("generatedDate", "asc")
          .limit(limit)
      )
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            const chDoc = a.payload.doc.data() as CohortCode;
            chDoc["selected"] = false;
            return chDoc;
          });
        })
      );
  }
  getClaimedCodes() {
    return this.afs
      .collection<CohortCode>("cohortCodes", ref =>
        ref.where("claimed", "==", true).orderBy("claimedDate", "asc")
      )
      .snapshotChanges()
      .pipe(
        map(changes => {
          return changes.map(a => {
            const chDoc = a.payload.doc.data() as CohortCode;
            chDoc["selected"] = false;
            return chDoc;
          });
        })
      );
  }
  getCodes(): Observable<CohortCode[]> {
    return this.afs.collection<CohortCode>("cohortCodes").valueChanges();
  }
  generateCodes(numberOfCodes: number) {
    const batch = this.afs.firestore.batch();
    const codeRef = this.afs.firestore.collection("cohortCodes");
    this.logger.debug("Generating ", numberOfCodes, " codes");
    const promiseArray = [];
    for (let v = 0; v < numberOfCodes; v++) {
      this.logger.debug("count", v);
      promiseArray.push(this.randomCode());
    }
    Promise.all(promiseArray)
      .then(codes => {
        this.logger.debug("codes", codes);
        codes.forEach(code => {
          const cohortCode = new CohortCode(code);
          batch.set(codeRef.doc(code), Object.assign({}, cohortCode));
        });
        batch.commit().then(batchResult => {
          this.logger.debug("batchResult", batchResult);
        });
      })
      .catch(err => {
        this.logger.debug("Code already exists");
        this.generateCodes(numberOfCodes);
      });
  }

  randomCode() {
    const code = Math.floor(100000 + Math.random() * 900000).toString();
    // const code = "485960";
    return new Promise((resolve, reject) => {
      this.afs.firestore
        .collection("cohortCodes")
        .where("cohortCode", "==", code)
        .limit(1)
        .get()
        .then(querySnapshot => {
          if (!querySnapshot.empty) {
            reject({ code: code });
          }
        })
        .then(x => {
          resolve(code);
        });
    });
  }
}
