import { Component, OnInit } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ResourceService } from "src/app/services/resource.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-resource-activity",
  templateUrl: "./resource-activity.component.html",
  styleUrls: ["./resource-activity.component.css"]
})
export class ResourceActivityComponent implements OnInit {
  resourceId: string;
  resource: any;
  resourceActivity: any;
  interactions: any;

  constructor(
    private logger: NGXLogger,
    private resourceService: ResourceService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.resourceId = this.route.snapshot.paramMap.get("resourceId");
    if (this.resourceId) {
      this.resourceService.getResource(this.resourceId).then(resource => {
        this.resource = resource;
        this.resourceService
          .getResourceActivity(this.resource)
          .subscribe(activity => {
            this.resourceActivity = activity;
            // this.logger.debug(
            //   "Resource found:",
            //   this.resource,
            //   this.resourceActivity
            // );
          });
        this.resourceService
          .getParticipantInteraction(this.resourceId)
          .subscribe(interactions => {
            this.interactions = interactions;
          });
      });
    }
  }
  trackFunctionForInteractions(idx, item) {
    return item.uuid;
  }
}
