import { Component, OnInit } from "@angular/core";
import { StatsService } from "../../stats/stats.service";
import { NGXLogger } from "ngx-logger";
import { Observable, from } from "rxjs";

@Component({
  selector: "app-resource-summary",
  templateUrl: "./resource-summary.component.html",
  styleUrls: ["./resource-summary.component.css"]
})
export class ResourceSummaryComponent implements OnInit {
  resourceStats: any = [];
  sessionStats: any;
  constructor(private statsService: StatsService, private logger: NGXLogger) {}

  ngOnInit() {
    const statLimit = 5;
    this.statsService.getResourceStats(statLimit).subscribe(statsResult => {
      this.resourceStats = statsResult.map(statResult => {
        return statResult;
      });
    });
    this.statsService.getSessionStats().subscribe(sessionStatsResult => {
      this.sessionStats = sessionStatsResult;
    });
  }
}
