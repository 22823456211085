import { Component, OnInit } from "@angular/core";
import { CodesService } from "../codes.service";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { CohortCode } from "models/cohort-code";
import { map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-codes-list",
  templateUrl: "./codes-list.component.html",
  styleUrls: ["./codes-list.component.css"]
})
export class CodesListComponent implements OnInit {
  unclaimedUnallocated: Observable<CohortCode[]>;
  allocatedCodes: Observable<CohortCode[]>;
  claimed: Observable<CohortCode[]>;
  limit = 10;
  selectedAllocatedCodes = {};
  cohortStats: any;
  getAll: string;

  constructor(
    private codesService: CodesService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: NGXLogger
  ) {}

  ngOnInit() {
    this.getAll = this.route.snapshot.paramMap.get("showAll");
    if (this.getAll === "showAllGenerated") {
      this.logger.debug("this.getAll", this.getAll);
      this.unclaimedUnallocated = this.codesService.getOpenCodes(-1);
    } else {
      this.unclaimedUnallocated = this.codesService.openCodes;
    }
    this.allocatedCodes = this.codesService.allocatedCodes;
    this.claimed = this.codesService.claimedCodes;
    this.codesService.getCohortStats().subscribe(results => {
      this.cohortStats = results;
    });
  }
  showAllUnallocated() {
    this.router.navigate(["/admin/codes/showAllGenerated"]);
  }
  onUnallocClick(code) {
    this.logger.debug("on click", code);
    if (!code.selected) {
      code.selected = true;
      this.selectedAllocatedCodes[code.cohortCode] = code.cohortCode;
    } else {
      code.selected = false;
      delete this.selectedAllocatedCodes[code.cohortCode];
    }
    this.logger.debug("checkbox clicked", this.selectedAllocatedCodes);
  }

  allocateCode(cohortCode: CohortCode) {
    this.codesService.allocateCode(cohortCode);
  }
  trackByCode(index, item) {
    if (!item) {
      return null;
    }
    return item.cohortCode;
  }
}
