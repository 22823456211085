import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NavComponent } from "./nav/nav.component";
import { AdminNavComponent } from "./admin-nav/admin-nav.component";
import { RouterModule } from "@angular/router";
import { SecondsPipe } from "../admin/seconds.pipe";
import { FirestoreDatePipe } from "../admin/timestamp.date.pipe";

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule],
  declarations: [
    NavComponent,
    AdminNavComponent,
    SecondsPipe,
    FirestoreDatePipe
  ],
  exports: [
    FormsModule,
    NavComponent,
    AdminNavComponent,
    SecondsPipe,
    FirestoreDatePipe
  ]
})
export class UiModule {}
