import { Upload } from "src/app/models/upload";
import * as firebase from "firebase/app";
import { Observable } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import * as moment from "moment";

export class UploadAdapter {
  private loader;
  private basePath = "/uploads";
  uploads: Observable<Upload[]>;
  private afs: AngularFirestore;
  resourceId: any;

  constructor(loader: any, afs: AngularFirestore, resourceId: string) {
    this.loader = loader;
    this.afs = afs;
    this.resourceId = resourceId;
  }
  public upload(): Promise<any> {
    // "data:image/png;base64,"+ btoa(binaryString)
    return this.readThis(this.loader.file);
  }
  readThis(file: File): Promise<any> {
    const stamp =
      moment()
        .format("YYYYMMDDssmmSS")
        .toString() + "_";
    return new Promise((resolve, reject) => {
      const storageRef = firebase.storage().ref();
      const upload = new Upload(file);
      const uploadTask = storageRef
        .child("" + this.basePath + "/images/" + stamp + file.name)
        .put(file, { customMetadata: { resourceId: this.resourceId } });

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          // upload in progress
          //   upload.progress =
          //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          // upload failed
          console.log("upload error:", error);
          reject("Error: " + error.message);
        },
        () => {
          // upload success
          uploadTask.snapshot.ref.getDownloadURL().then(url => {
            upload.url = url;
            upload.name = stamp + file.name;
            upload.fileType = file.type;
            upload.fileLastModified = new Date(file.lastModified);
            upload.fileSize = file.size;
            this.saveFileData(upload).then(() => {
              resolve({ default: upload.url });
            });
          });
        }
      );
    });
  }

  private saveFileData(upload: Upload) {
    return this.afs
      .collection(this.basePath)
      .add(Object.assign({}, upload))
      .then(foo => {})
      .catch(err => {
        console.log("error: ", err);
        throw err;
      });
  }
}
