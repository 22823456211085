import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UiModule } from "../../ui/ui.module";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { EditorModule } from "@tinymce/tinymce-angular";

import { ResourceListComponent } from "./resource-list/resource-list.component";
import { ResourceEditComponent } from "./resource-edit/resource-edit.component";
import { MarkdownModule } from "ngx-markdown";
import { LMarkdownEditorModule } from "ngx-markdown-editor";
import { ResourceSummaryComponent } from "./resource-summary/resource-summary.component";
import { RouterModule } from "@angular/router";
import { ResourceDashboardComponent } from "./resource-dashboard/resource-dashboard.component";
import { ResourceActivityComponent } from "./resource-activity/resource-activity.component";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  imports: [
    CommonModule,
    UiModule,
    MarkdownModule.forChild(),
    LMarkdownEditorModule,
    RouterModule,
    CKEditorModule,
    EditorModule,
    AngularFireStorageModule,
    DragDropModule
  ],
  declarations: [
    ResourceListComponent,
    ResourceEditComponent,
    ResourceSummaryComponent,
    ResourceDashboardComponent,
    ResourceActivityComponent
  ],
  exports: [ResourceSummaryComponent]
})
export class ResourcesModule {}
