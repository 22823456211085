import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Device } from "models/device";
import { NGXLogger } from "ngx-logger";
import { DeviceUUID } from "device-uuid";
import * as moment from "moment";
import { Observable } from "rxjs";
const Fingerprint2 = require("fingerprintjs2");

@Injectable({
  providedIn: "root"
})
export class DeviceService {
  constructor(private afs: AngularFirestore, private logger: NGXLogger) {}

  async getDeviceUUID() {
    const uuid = await new Promise((resolve, reject) => {
      new Fingerprint2().get((result, components) => {
        resolve(result);
      });
    });
    return uuid;
  }
  getDevices(): Observable<Device[]> {
    return this.afs.collection<Device>("devices").valueChanges();
  }
  updateDevice(
    device: Device,
    deviceInfo: any,
    cohortCode?: string,
    newDevice = false
  ) {
    this.logger.debug("DeviceService: Updating device: ", device);
    const deviceDoc = { deviceInfo: deviceInfo };
    if (!device.firstSeen) {
      device.firstSeen = new Date();
    }
    if (cohortCode) {
      deviceDoc["cohortCode"] = cohortCode;
    }
    const deviceData = Object.assign(device, deviceDoc);
    this.logger.debug(
      "DeviceService: Updating device with deviceData: ",
      deviceData
    );
    if (newDevice) {
      return this.afs
        .collection<Device>("devices")
        .doc(device.deviceUUID)
        .set(deviceData, { merge: true });
    } else {
      return this.afs
        .collection<Device>("devices")
        .doc(device.deviceUUID)
        .update(deviceData);
    }
  }

  getDevice(deviceUUID: string) {
    this.logger.debug("DeviceService: Getting device: ", deviceUUID);
    return new Promise((resolve, reject) => {
      this.afs.firestore
        .collection("devices")
        .doc(deviceUUID)
        .get()
        .then(deviceData => {
          this.logger.debug(
            "DeviceService: Existing device",
            deviceData.id,
            deviceData.data()
          );
          if (deviceData.exists && deviceData.id) {
            const theDevice = deviceData.data() as Device;
            this.logger.debug("DeviceService: Existing device", theDevice);
            resolve(theDevice);
          } else {
            // seeing device for first time ::: Doesn't seem to ever get here
            const newDevice = new Device();
            delete newDevice.cohortCodes;
            newDevice.deviceUUID = deviceUUID;
            this.logger.debug(
              "DeviceService: Seeing device for the first time, so its new:",
              newDevice
            );
            this.afs.firestore
              .collection("devices")
              .doc(newDevice.deviceUUID)
              .set(Object.assign({}, newDevice))
              .then(() => {
                resolve(newDevice);
              });
          }
        });
    });
  }
}
