import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LMarkdownEditorModule } from "ngx-markdown-editor";
import { HighchartsChartModule } from "highcharts-angular";

import { AdminRoutingModule } from "./admin-routing.module";
import { AdminDashboardComponent } from "./admin-dashboard/admin-dashboard.component";
import { AdminComponent } from "./admin/admin.component";
import { UiModule } from "../ui/ui.module";
import { ResourcesModule } from "./resources/resources.module";
import { MarkdownModule } from "ngx-markdown";
import { SlugifyPipe } from "../shared/slugify.pipe";
import { CodesComponent } from "./codes/codes.component";
import { CodesModule } from "./codes/codes.module";
import { CodeSummaryComponent } from "./codes/code-summary/code-summary.component";
import { ParticipantSummaryComponent } from "./participants/participant-summary/participant-summary.component";
import { ResourceSummaryComponent } from "./resources/resource-summary/resource-summary.component";
import { ParticipantsModule } from "./participants/participants.module";
import { SecondsPipe } from "./seconds.pipe";
@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    ResourcesModule,
    ParticipantsModule,
    CodesModule,
    UiModule,
    HighchartsChartModule,
  ],
  declarations: [
    AdminDashboardComponent,
    AdminComponent,
    SlugifyPipe,
    ParticipantSummaryComponent
  ],
  exports: [SlugifyPipe, SecondsPipe],
  providers: [SlugifyPipe]
})
export class AdminModule {}
