import { Injectable } from "@angular/core";
import { AngularFirestore, QuerySnapshot } from "@angular/fire/firestore";
import { Resource } from "models/resource";
import { map } from "rxjs/operators";
import { NGXLogger } from "ngx-logger";

@Injectable({
  providedIn: "root"
})
export class ResourceService {
  constructor(private afs: AngularFirestore, private logger: NGXLogger) {}

  getPage(resourceSlug: string) {
    const type = "PAGE";
    const resourceCollection = this.afs.collection<Resource>("resources", ref =>
      ref
        .where("slug", "==", resourceSlug)
        .where("deleted", "==", false)
        .where("type", "==", type)
        .limit(1)
    );
    return new Promise((resolve, reject) => {
      resourceCollection
        .get()
        .toPromise()
        .then((querySnapshot: QuerySnapshot<Resource>) => {
          if (querySnapshot.empty) {
            // 404 page not found
            reject("Page not found");
          } else {
            resolve(querySnapshot.docs[0].data());
          }
        });
    });
  }

  getNewOrder() {
    const order = 999;
    return this.afs.firestore
      .collection("resources")
      .orderBy("order", "desc")
      .limit(1)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          const highestKnownOrder = querySnapshot.docs[0].data().order;
          const nextOrder = Math.ceil(highestKnownOrder / 10) * 10;
          return Promise.resolve(nextOrder);
        } else {
          return Promise.resolve(order);
        }
      });
  }

  updateOrder(resource: Resource, previousIndex, currentIndex) {
    const resourcesToUpdate = [];
    if (previousIndex < currentIndex) {
      // reorder all from previousIndex to end of list
      // get all resources with order >= previousIndex
      console.log("previousIndex < currentIndex", previousIndex < currentIndex);
      this.afs.firestore
        .collection("resources")
        .where("order", ">=", +previousIndex)
        .where("order", "<=", +currentIndex)
        .get()
        .then((querySnapshot: QuerySnapshot<Resource>) => {
          querySnapshot.docs.forEach((res, idx) => {
            const theResource = res.data() as Resource;
            if (theResource.id !== resource.id) {
              console.log("idx", idx, "res", res.data());
              theResource.order = theResource.order - 1;
              resourcesToUpdate.push(theResource);
            } else {
              resource.order = currentIndex;
              resourcesToUpdate.push(resource);
            }
          });
          return this.batchSaveResource(resourcesToUpdate);
        });
    } else if (previousIndex > currentIndex) {
      console.log("previousIndex > currentIndex", previousIndex > currentIndex);
      // reorder all from currentIndex+1 to EOL
      // get all resources with order > currentIndex
      this.afs.firestore
        .collection("resources")
        .where("order", ">=", currentIndex)
        .where("order", "<", previousIndex)
        .get()
        .then((querySnapshot: QuerySnapshot<Resource>) => {
          console.log(querySnapshot);
          querySnapshot.docs.forEach((res, idx) => {
            const theResource = res.data() as Resource;
            if (theResource.id !== resource.id) {
              console.log("theResource", theResource);
              theResource.order = theResource.order + 1;
              resourcesToUpdate.push(theResource);
            }
          });
          console.log("resource", resource);
          resource.order = currentIndex;
          resourcesToUpdate.push(resource);
          return this.batchSaveResource(resourcesToUpdate);
        });
    }

    // let resourceWithNewIndex: Resource;
    // this.afs.firestore
    //   .collection("resources")
    //   .where("order", "==", currentIndex)
    //   .limit(1)
    //   .get()
    //   .then(querySnapshot => {
    //     resourceWithNewIndex = querySnapshot.docs[0].data() as Resource;
    //     resourceWithNewIndex.order = +previousIndex;
    //     resource.order = +currentIndex;
    //     return this.batchSaveResource([resourceWithNewIndex, resource]);
    //   });
  }
  batchSaveResource(resources: Resource[]) {
    const batch = this.afs.firestore.batch();
    const resourceCollectionRef = this.afs.firestore.collection("resources");
    resources.forEach(resource => {
      batch.set(resourceCollectionRef.doc(resource.id), resource, {
        merge: true
      });
    });
    return batch.commit();
  }

  rebasePageOrder() {
    const resourcesToUpdate = [];
    this.afs.firestore
      .collection("resources")
      .where("deleted", "==", false)
      .orderBy("order", "asc")
      .orderBy("name", "asc")
      .get()
      .then((querySnapshot: QuerySnapshot<Resource>) => {
        querySnapshot.docs.forEach((doc, idx) => {
          const resource = doc.data() as Resource;
          resource.order = idx;
          resourcesToUpdate.push(resource);
        });
        return this.batchSaveResource(resourcesToUpdate);
      })
      .catch(err => {
        console.error("Error reordering resources", err);
      });
  }

  saveResource(resource: Resource) {
    const resourceReference = this.afs.doc<Resource>(
      "resources/" + resource.id
    );
    resource["searchFilter"] = this.createResourceSearchFilterRef(
      resource.name
    );
    resource.order = +resource.order;
    return resourceReference.ref.get().then(docSnapshot => {
      if (docSnapshot.exists) {
        return new Promise((resolve, reject) => {
          resourceReference
            .update(Object.assign({}, resource))
            .then(resAction => {
              resolve("Resource updated");
            })
            .catch(err => {
              reject(err);
            });
        });
      } else {
        return new Promise((resolve, reject) => {
          resourceReference
            .set(Object.assign({}, resource))
            .then(resAction => {
              resolve("Resource updated");
            })
            .catch(err => {
              reject(err);
            });
        });
      }
    });
  }

  createResourceSearchFilterRef(resourceName: string) {
    const returnFilterObj = {};
    if (resourceName.length) {
      returnFilterObj["name"] = resourceName;
      returnFilterObj["parts"] = [];
      for (let i = 1; i <= resourceName.length; i++) {
        returnFilterObj["parts"].push(
          resourceName.substring(0, i).toLowerCase()
        );
      }
      const nameArray = resourceName.split(" ");
      // split name on spaces
      if (nameArray.length) {
        nameArray.forEach(part => {
          returnFilterObj["parts"].push(part);
          for (let i = 1; i <= part.length; i++) {
            returnFilterObj["parts"].push(part.substring(0, i).toLowerCase());
          }
        });
      }
    }
    return returnFilterObj["parts"];
  }

  getResource(resourceId: string) {
    const resourceReference = this.afs.doc<Resource>("resources/" + resourceId);
    return new Promise((resolve, reject) => {
      resourceReference.valueChanges().subscribe(resource => {
        resolve(resource);
      });
    });
  }

  deleteResource(resourceId: string) {
    return this.afs.firestore
      .doc("resources/" + resourceId)
      .get()
      .then(docSnapshot => {
        const resource = docSnapshot.data() as Resource;
        this.logger.debug("deleting resource", resource);
        if (!resource.deleted) {
          resource.deleted = true;
          resource.order = 99999999;
          this.logger.debug("date deleting resource", resource);
          return this.afs.firestore
            .collection("resources")
            .doc(resourceId)
            .set(Object.assign({}, resource), { merge: true })
            .then(() => {
              return resource;
            });
        } else {
          return resource;
        }
      });
  }

  undoDelete(resourceId: string) {
    return this.afs.firestore
      .doc("resources/" + resourceId)
      .get()
      .then(docSnapshot => {
        const resource = docSnapshot.data() as Resource;
        this.logger.debug("about to undelete resource", resource);
        if (resource.deleted) {
          resource.deleted = false;
          this.logger.debug("undeleting resource", resource);
          return this.afs.firestore
            .collection("resources")
            .doc(resourceId)
            .set(Object.assign({}, resource), { merge: true })
            .then(() => {
              return resource;
            });
        } else {
          return resource;
        }
      });
  }

  getNextResource(resource: Resource) {
    return this.afs.firestore
      .doc("resources/" + resource.id)
      .get()
      .then(docSnapshot => {
        return this.afs.firestore
          .collection("resources")
          .where("deleted", "==", false)
          .orderBy("order", "asc")
          .startAfter(docSnapshot)
          .limit(1)
          .get();
      })
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          return querySnapshot.docs[0].data() as Resource;
        } else {
          return null;
        }
      });
  }

  getResourceActivity(resource: Resource) {
    if (resource) {
      return this.afs
        .collection("resources")
        .doc(resource.id)
        .snapshotChanges()
        .pipe(
          map(a => {
            const data = a.payload.data();
            const id = a.payload.id;
            data["stats"] = {};
            this.afs
              .collection("stats")
              .doc("resourceStats")
              .collection("resources")
              .doc(id)
              .valueChanges()
              .subscribe(hitData => {
                data["stats"]["uniqueCohort"] = 0;
                if (
                  hitData["uniqueDevices"] &&
                  hitData["uniqueDevices"]["cohort"]
                ) {
                  data["stats"]["uniqueCohort"] =
                    hitData["uniqueDevices"]["cohort"];
                }
                data["stats"]["uniqueNonCohort"] = 0;
                if (
                  hitData["uniqueDevices"] &&
                  hitData["uniqueDevices"]["nonCohort"]
                ) {
                  data["stats"]["uniqueNonCohort"] =
                    hitData["uniqueDevices"]["nonCohort"];
                }
                data["stats"]["hitCountTotal"] = hitData["hitCountTotal"];
                data["stats"]["cohortHitTotal"] =
                  hitData["allTime"]["cohortHitTotal"];
                data["stats"]["nonCohortHitTotal"] =
                  hitData["allTime"]["nonCohortHitTotal"];
                data["stats"]["cohortTimerTotal"] =
                  hitData["allTime"]["cohortTimerTotal"];
                data["stats"]["nonCohortTimerTotal"] =
                  hitData["allTime"]["nonCohortTimerTotal"];
                data["stats"]["cohortTimerAverage"] =
                  hitData["allTime"]["cohortTimerAverage"];
                data["stats"]["nonCohortTimerAverage"] =
                  hitData["allTime"]["nonCohortTimerAverage"];
              });
            return data;
          })
        );
    }
  }

  getNavResources() {
    return this.afs
      .collection("resources", ref => {
        let query:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = ref;
        query = query.where("showInNavigation", "==", true);
        query = query.where("deleted", "==", false);
        query = query.where("type", "==", "PAGE").orderBy("order", "asc");
        return query;
      })
      .valueChanges();
  }
  getResources(searchFilter: string, type: string = "PAGE") {
    return this.afs
      .collection("resources", ref => {
        let query:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = ref;
        if (searchFilter.length >= 3) {
          query = query.where(
            "searchFilter",
            "array-contains",
            searchFilter.toLowerCase()
          );
        }
        query = query.where("deleted", "==", false);
        query = query.where("type", "==", type).orderBy("order", "asc");
        return query;
      })
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            data["stats"] = {};
            this.afs
              .collection("stats")
              .doc("resourceStats")
              .collection("resources")
              .doc(id)
              .valueChanges()
              .subscribe(hitData => {
                if (hitData) {
                  data["stats"]["uniqueCohort"] = 0;
                  if (
                    hitData["uniqueDevices"] &&
                    hitData["uniqueDevices"]["cohort"]
                  ) {
                    data["stats"]["uniqueCohort"] =
                      hitData["uniqueDevices"]["cohort"];
                  }
                  data["stats"]["uniqueNonCohort"] = 0;
                  if (
                    hitData["uniqueDevices"] &&
                    hitData["uniqueDevices"]["nonCohort"]
                  ) {
                    data["stats"]["uniqueNonCohort"] =
                      hitData["uniqueDevices"]["nonCohort"];
                  }
                  data["stats"]["hitCountTotal"] = hitData["hitCountTotal"];
                  data["stats"]["cohortHitTotal"] =
                    hitData["allTime"]["cohortHitTotal"];
                  data["stats"]["nonCohortHitTotal"] =
                    hitData["allTime"]["nonCohortHitTotal"];
                  data["stats"]["cohortTimerTotal"] =
                    hitData["allTime"]["cohortTimerTotal"];
                  data["stats"]["nonCohortTimerTotal"] =
                    hitData["allTime"]["nonCohortTimerTotal"];
                  data["stats"]["cohortTimerAverage"] =
                    hitData["allTime"]["cohortTimerAverage"];
                  data["stats"]["nonCohortTimerAverage"] =
                    hitData["allTime"]["nonCohortTimerAverage"];
                }
              });
            return data;
          })
        )
      );
  }
  getParticipantInteraction(resourceId: string, limit: number = 10, startAt?) {
    const startAtRecord = startAt !== undefined ? startAt : 1;
    return this.afs
      .collection("stats")
      .doc("resourceStats")
      .collection("resources")
      .doc(resourceId)
      .collection("devices")
      .valueChanges();
    // , ref => ref.orderBy("timestamp", "desc")
  }
}
