import {
  Component,
  OnInit,
  HostListener,
  NgZone,
  ViewEncapsulation
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { Device } from "models/device";
import { SessionLoggingService } from "../services/session-logging.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { Session } from "models/session";
import { Router, ActivatedRoute } from "@angular/router";
import { ResourceService } from "../services/resource.service";
import { Resource } from "models/resource";
import { ulid } from "ulid";
import * as moment from "moment";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-resource",
  templateUrl: "./resource.component.html",
  styleUrls: ["./resource.component.css"]
})
export class ResourceComponent implements OnInit {
  cohortCode: string;
  componentAction: any;
  deviceUUID: string;
  userService: any;
  deviceService: any;
  session: any;
  resourceSlug: any;
  resource: Resource;
  startTimestamp = Date.now();
  timer: any = Date.now();
  intervalTimer: any;
  timerDurationInSeconds: number;
  actionItemLogId: string;
  actionItemHitId: string;
  nextResource: Resource;
  resourceStyleTag: any;
  public version: string = environment.VERSION;
  constructor(
    private resourceService: ResourceService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: NGXLogger,
    private sessionLogger: SessionLoggingService,
    private deviceDetectorService: DeviceDetectorService,
    private _ngZone: NgZone,
    private sanitizer: DomSanitizer
  ) {}

  // todo: see if I can fix this, it doesn't always work
  @HostListener("window:beforeunload", ["$event"])
  leaveLogging(event?: Event) {
    const loggingData = {};
    const hitData = {};
    hitData["resourceID"] = this.resource.id;
    loggingData["timestamp"] = new Date();
    loggingData["action"] = "RESOURCE";
    loggingData["resourceID"] = this.resource.id;
    if (this.resource.slug) {
      loggingData["slug"] = this.resource.slug;
    }
    loggingData["timeOnPage"] = this.timerDurationInSeconds;
    loggingData["leftPage"] = true;

    return this.sessionLogger
      .timingLog(
        this.deviceUUID,
        this.session.sessionUUID,
        "RESOURCE",
        loggingData,
        this.session,
        this.cohortCode,
        this.actionItemLogId,
        this.actionItemHitId,
        hitData
      )
      .then(() => {
        clearInterval(this.intervalTimer);
        event.returnValue = true;
      });
  }
  loadResource(resourceSlug: string) {
    window.location.href = "/resource/" + resourceSlug;
  }
  ngOnInit() {
    // this.cohortCode = "";
    // this.actionItemLogId = ulid();
    // this.actionItemHitId = ulid();
    // let dateNow = new Date();
    this.sessioninfo().then(session => {
      return this.route.params.subscribe(params => {
        this.cohortCode = "";
        this.actionItemLogId = ulid();
        this.actionItemHitId = ulid();
        const dateNow = new Date();
        this.resourceSlug = params["resourceSlug"];
        const loggingData = {};
        const hitData = {};
        if (this.resourceSlug) {
          this.resourceService
            .getPage(this.resourceSlug)
            .then(resource => {
              this.resource = resource as Resource;
              if (this.resource.resourceStyles) {
                const head = document.getElementsByTagName("head")[0];
                const styleTag = document.createElement("style");
                styleTag.appendChild(
                  document.createTextNode(this.resource.resourceStyles)
                );
                head.appendChild(styleTag);
              }
              if (this.resource.resourceScripts) {
                const head = document.getElementsByTagName("head")[0];
                const scriptTag = document.createElement("script");
                scriptTag.type = "text/javascript";
                scriptTag.appendChild(
                  document.createTextNode(this.resource.resourceScripts)
                );
                head.appendChild(scriptTag);
              }
              // see if there is a next resource
              this.resourceService
                .getNextResource(this.resource)
                .then(nextResource => {
                  this.nextResource = nextResource;
                });

              // loggingData["action"] = "RESOURCE";
              // loggingData["resourceID"] = this.resource.id;
              loggingData["timestamp"] = dateNow;
              loggingData["generated"] = this.session.generated;
              hitData["hitId"] = this.actionItemHitId;
              hitData["firstSeen"] = dateNow;
              hitData["action"] = "RESOURCE";
              hitData["resourceID"] = this.resource.id;
              if (this.resource.slug) {
                hitData["slug"] = this.resource.slug;
                hitData["name"] = this.resource.name;
              }

              hitData["deviceInfo"] = {};
              hitData["deviceInfo"][
                "info"
              ] = this.deviceDetectorService.getDeviceInfo();
              hitData["deviceInfo"][
                "isMobile"
              ] = this.deviceDetectorService.isMobile();
              hitData["deviceInfo"][
                "isTablet"
              ] = this.deviceDetectorService.isTablet();
              hitData["deviceInfo"][
                "isDesktop"
              ] = this.deviceDetectorService.isDesktop();

              this.sessionLogger
                .actionLog(
                  this.deviceUUID,
                  this.session.sessionUUID,
                  "RESOURCE",
                  loggingData,
                  this.session,
                  this.cohortCode,
                  this.actionItemLogId,
                  this.actionItemHitId,
                  hitData
                )
                .then(result => {
                  this.logger.debug("logging result", result);
                  this.actionItemLogId = result["itemId"];
                  this.actionItemHitId = result["hitId"];
                })
                .catch(err => {
                  this.logger.debug("in catch::", err);
                });
            })
            .catch(err => {
              this.logger.debug("Find Resource error", err);
              this.router.navigate(["/404"], {
                queryParams: { err: err, slug: this.resourceSlug }
              });
            });
          this.startTimer(loggingData, hitData);
        }
      });
    });
  }
  startTimer(loggingData: any, hitData: any) {
    this.intervalTimer = setInterval(() => {
      this.timer = Date.now();
      this.timerDurationInSeconds = Math.round(
        (this.timer.valueOf() - this.startTimestamp.valueOf()) / 1000
      );

      if (
        this.timerDurationInSeconds > 9 &&
        this.timerDurationInSeconds % 10 === 0 &&
        this.timerDurationInSeconds <= 900
      ) {
        hitData["timeOnPage"] = this.timerDurationInSeconds;

        this.sessionLogger.timingLog(
          this.deviceUUID,
          this.session.sessionUUID,
          "RESOURCE",
          loggingData,
          this.session,
          this.cohortCode,
          this.actionItemLogId,
          this.actionItemHitId,
          hitData
        );
      }
    }, 1000);
  }
  sessioninfo() {
    return new Promise((resolve, reject) => {
      const loggingData = {};
      loggingData["timestamp"] = new Date();
      loggingData["action"] = this.componentAction;
      loggingData["deviceInfo"] = {};
      let theDevice = new Device();

      const localStoredDeviceUUID = localStorage.getItem("help-deviceUUID");

      this.cohortCode = sessionStorage.getItem("help-session-cohortCode");
      const sessionUUID = sessionStorage.getItem("help-session-uuid");
      const sessionGenerated = moment(
        sessionStorage.getItem("help-session-generated")
      ).toDate(); // parse the session string to a date
      const sessionDeviceUUID = sessionStorage.getItem(
        "help-session-deviceUUID"
      );
      if (localStoredDeviceUUID) {
        this.logger.debug("Found local deviceUUID", localStoredDeviceUUID);
        loggingData["existingDevice"] = true;
        this.deviceUUID = localStoredDeviceUUID;
      } else {
        this.logger.debug("No local deviceUUID found");
        loggingData["existingDevice"] = false;
        this.deviceUUID = this.userService.getDeviceUUID();
        this.deviceService.getDevice(this.deviceUUID).then(deviceRecord => {
          this.logger.debug("Device record found", deviceRecord);
          theDevice = deviceRecord as Device;
          localStorage.setItem("help-deviceUUID", this.deviceUUID);
          this.logger.debug("Device uuid", this.deviceUUID);
        });
        if (!theDevice.deviceInfo) {
          loggingData["deviceInfo"] = {};
        }
        loggingData["deviceInfo"][
          "info"
        ] = this.deviceDetectorService.getDeviceInfo();
        loggingData["deviceInfo"][
          "isMobile"
        ] = this.deviceDetectorService.isMobile();
        loggingData["deviceInfo"][
          "isTablet"
        ] = this.deviceDetectorService.isTablet();
        loggingData["deviceInfo"][
          "isDesktop"
        ] = this.deviceDetectorService.isDesktop();
      }
      if (sessionUUID && sessionDeviceUUID === this.deviceUUID) {
        this.session = Object.assign({
          generated: sessionGenerated,
          sessionUUID: sessionUUID,
          deviceUUID: sessionDeviceUUID
        }) as Session;
        this.logger.debug("Existing session found", this.session);
      } else {
        this.session = new Session(this.deviceUUID);
        this.logger.debug("New session created", this.session);
        // TODO: log this so we can track where/when they started their session
      }
      sessionStorage.setItem("help-session-uuid", this.session.sessionUUID);
      sessionStorage.setItem(
        "help-session-generated",
        this.session.generated.toISOString()
      );
      sessionStorage.setItem(
        "help-session-deviceUUID",
        this.session.deviceUUID
      );
      resolve(this.session);
    });
  }
}
