// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyD8uq_ELLHuYEugnvR-D4d_9XfAnUhw2z8",
    authDomain: "help-client-app-dev-218203.firebaseapp.com",
    databaseURL: "https://help-client-app-dev-218203.firebaseio.com",
    projectId: "help-client-app-dev-218203",
    storageBucket: "help-client-app-dev-218203.appspot.com",
    messagingSenderId: "429135688270",
    clientId:
      "429135688270-ghjf2sqhdgr8km6oqku2126kptdolanq.apps.googleusercontent.com"
  },
  VERSION: require("../../package.json").version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import "zone.js/dist/zone-error"; // Included with Angular CLI.
