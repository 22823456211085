import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ParticipantsDashboardComponent } from "./participants-dashboard/participants-dashboard.component";
import { FormsModule } from "@angular/forms";
import { AdminModule } from "../admin.module";
import { FirestoreDatePipe } from "../timestamp.date.pipe";
import { SecondsPipe } from "../seconds.pipe";
import { ParticipantComponent } from "./participant/participant.component";
import { RouterModule } from "@angular/router";
import { YYYYMMDDDatePipe } from "../yyyymmdd.date.pipe";
import { MillisecondsPipe } from "../milliseconds.pipe";
import { DeviceInfoPipe } from "../deviceInfo.pipe";
import { UiModule } from "src/app/ui/ui.module";

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, UiModule],
  declarations: [
    ParticipantsDashboardComponent,
    MillisecondsPipe,
    YYYYMMDDDatePipe,
    DeviceInfoPipe,
    ParticipantComponent
  ]
})
export class ParticipantsModule {}
