import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { NGXLogger } from "ngx-logger";
import { Session } from "models/session";
import { Device } from "models/device";
import { ulid } from "ulid";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class SessionLoggingService {
  constructor(private afs: AngularFirestore, private logger: NGXLogger) {}

  timingLog(
    deviceUUID: string,
    sessionUUID: string,
    action: string,
    data: any,
    session?: Session,
    cohortCode?: string,
    existingItemId?: string,
    existingHitId?: string,
    hitData: any = {}
  ) {
    const now = new Date();
    const itemId = existingItemId ? existingItemId : ulid();
    const hitId = existingHitId ? existingHitId : ulid();

    data.timestamp = now;
    if (!session) {
      session = Object.assign({
        sessionUUID: sessionUUID,
        deviceUUID: deviceUUID
      });
    }
    if (!cohortCode) {
      cohortCode = null;
    }
    if (deviceUUID === undefined) {
      throw new Error("No deviceUUID found");
    }
    return new Promise((resolve, reject) => {
      this.logger.debug(
        "Logging action for arguments:",
        "itemId",
        itemId,
        "hitId",
        hitId,
        "deviceUUID:",
        deviceUUID,
        " sessionUUID:",
        sessionUUID,
        " action: ",
        action,
        " data:",
        data
      );
      this.afs
        .collection<Device>("devices")
        .doc(deviceUUID)
        .set({ lastSeen: now }, { merge: true })
        .then(() => {
          const sessionData = { lastSeen: now, cohortCode: cohortCode };
          return this.afs
            .collection("devices")
            .doc(deviceUUID)
            .collection("sessions")
            .doc(sessionUUID)
            .set(sessionData, { merge: true });
        })
        .then(result => {
          return this.afs
            .collection("devices")
            .doc(deviceUUID)
            .collection("sessions")
            .doc(sessionUUID)
            .collection("actions")
            .doc(action)
            .collection("items")
            .doc(itemId)
            .set(
              {
                data: Object.assign(hitData, {
                  lastSeen: now
                }),
                sessionUUID: sessionUUID,
                deviceUUID: deviceUUID,
                cohortCode: cohortCode
              },
              { merge: true }
            );
        })
        .then(() => {
          return this.afs
            .collection("interactions")
            .doc(action)
            .collection("hits")
            .doc(hitId)
            .set(
              {
                uuid: hitId,
                data: data,
                action: action,
                sessionUUID: sessionUUID,
                sessionItemUUID: itemId,
                deviceUUID: deviceUUID,
                cohortCode: cohortCode
              },
              { merge: true }
            );
        })
        .then(() => {
          if (action === "RESOURCE" && hitData.resourceID) {
            return this.afs
              .collection("resources")
              .doc(hitData.resourceID)
              .collection("hits")
              .doc(hitId)
              .set(
                {
                  uuid: hitId,
                  data: data,
                  action: action,
                  sessionUUID: sessionUUID,
                  sessionItemUUID: itemId,
                  deviceUUID: deviceUUID,
                  cohortCode: cohortCode
                },
                { merge: true }
              );
          }
        })
        .then(() => {
          if (cohortCode) {
            return this.afs
              .collection("cohortCodes")
              .doc(cohortCode.toString())
              .collection("devices")
              .doc(deviceUUID)
              .set({ deviceUUID: deviceUUID, updated: now }, { merge: true });
          }
        })
        .then(result => {
          this.logger.debug("Logged session action:", itemId);
          resolve({ message: "Updated", itemId: itemId, hitId: hitId });
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  actionLog(
    deviceUUID: string,
    sessionUUID: string,
    action: string,
    data: any,
    session?: Session,
    cohortCode?: string,
    existingItemId?: string,
    existingHitId?: string,
    hitData: any = {}
  ) {
    const now = new Date();
    const itemId = existingItemId ? existingItemId : ulid();
    const hitId = existingHitId ? existingHitId : ulid();
    const hitDataObj = Object.keys(hitData) ? hitData : {};
    let sessionObj = {};
    if (cohortCode) {
      data["cohortCode"] = cohortCode;
    }

    if (!session) {
      sessionObj = Object.assign({
        sessionUUID: sessionUUID,
        deviceUUID: deviceUUID,
        generated: new Date(),
        lastSeen: new Date()
      });
    } else {
      sessionObj = Object.assign({
        sessionUUID: session.sessionUUID,
        deviceUUID: session.deviceUUID,
        generated: session.generated,
        lastSeen: new Date()
      });
    }
    if (!cohortCode) {
      cohortCode = null;
    }
    if (deviceUUID === undefined) {
      throw new Error("No deviceUUID found");
    }
    return new Promise((resolve, reject) => {
      this.logger.debug(
        "Logging action for arguments:",
        "itemId",
        itemId,
        "hitId",
        hitId,
        "deviceUUID:",
        deviceUUID,
        " sessionUUID:",
        sessionUUID,
        " action: ",
        action,
        " data:",
        data,
        "sessionObj",
        sessionObj,
        "hitDataObj",
        hitDataObj
      );
      this.afs
        .collection<Device>("devices")
        .doc(deviceUUID)
        .set(Object.assign({ deviceUUID: deviceUUID, lastSeen: now }), {
          merge: true
        })
        .then(() => {
          return this.afs
            .collection("devices")
            .doc(deviceUUID)
            .collection("sessions")
            .doc(sessionUUID)
            .set(sessionObj, { merge: true });
        })
        .then(result => {
          return this.afs
            .collection("devices")
            .doc(deviceUUID)
            .collection("sessions")
            .doc(sessionUUID)
            .collection("actions")
            .doc(action)
            .collection("items")
            .doc(itemId)
            .set(
              {
                data: Object.assign(hitDataObj, {
                  lastSeen: now
                }),
                sessionUUID: sessionUUID,
                deviceUUID: deviceUUID,
                cohortCode: cohortCode
              },
              { merge: true }
            );
        })
        .then(() => {
          return this.afs
            .collection("interactions")
            .doc(action)
            .collection("hits")
            .doc(hitId)
            .set({
              uuid: hitId,
              timestamp: now,
              data: hitDataObj,
              action: action,
              sessionUUID: sessionUUID,
              sessionItemUUID: itemId,
              deviceUUID: deviceUUID,
              cohortCode: cohortCode
            });
        })
        .then(() => {
          if (cohortCode) {
            return this.afs
              .collection("stats")
              .doc("cohortSessions")
              .collection("data")
              .doc(sessionUUID)
              .set(
                {
                  timestamp: now,
                  sessionData: {
                    sessionUUID: sessionUUID,
                    deviceUUID: deviceUUID,
                    cohortCode: cohortCode
                  }
                },
                { merge: true }
              );
          } else {
            return this.afs
              .collection("stats")
              .doc("nonCohortSessions")
              .collection("data")
              .doc(sessionUUID)
              .set(
                {
                  timestamp: now,
                  sessionData: {
                    sessionUUID: sessionUUID,
                    deviceUUID: deviceUUID
                  }
                },
                { merge: true }
              );
          }
        })
        .then(() => {
          if (action === "RESOURCE" && hitDataObj.resourceID) {
            return this.afs
              .collection("resources")
              .doc(hitDataObj.resourceID)
              .collection("hits")
              .doc(hitId)
              .set({
                uuid: hitId,
                timestamp: now,
                data: hitDataObj,
                action: action,
                sessionUUID: sessionUUID,
                sessionItemUUID: itemId,
                deviceUUID: deviceUUID,
                cohortCode: cohortCode
              });
          }
        })

        .then(() => {
          if (action === "RESOURCE" && hitDataObj.resourceID) {
            return this.afs
              .collection("resources")
              .doc(hitDataObj.resourceID)
              .collection("devices")
              .doc(deviceUUID)
              .set(
                {
                  uuid: hitId,
                  timestamp: now,
                  data: hitDataObj,
                  action: action,
                  sessionUUID: sessionUUID,
                  sessionItemUUID: itemId,
                  deviceUUID: deviceUUID,
                  cohortCode: cohortCode
                },
                { merge: true }
              );
          }
        })
        .then(() => {
          if (cohortCode) {
            return this.afs
              .collection("cohortCodes")
              .doc(cohortCode.toString())
              .collection("devices")
              .doc(deviceUUID)
              .set({ deviceUUID: deviceUUID, updated: now });
          }
        })
        .then(result => {
          this.logger.debug("Logged session action:", itemId);
          resolve({ message: "Updated", itemId: itemId, hitId: hitId });
        })
        .catch(err => {
          this.logger.error("Session Logging error", err);
          reject(err);
        });
    });
  }

  logSessionAction(session: Session, action: string, information: any) {
    this.logger.debug("Logging session action:", session, action, information);
    return this.actionLog(
      information.theDevice.deviceUUID,
      session.sessionUUID,
      action,
      information.data
    );
    // return new Promise((resolve, reject) => {
    //   // this.afs.collection<Device>('devices').doc(deviceUUID).update(Object.assign({}, theDevice))
    //   const newItemId = ulid();
    //   this.logger.debug("information.theDevice", information.theDevice);
    //   this.afs
    //     .collection<Device>("devices")
    //     .doc(information.deviceUUID)
    //     .update(Object.assign({ lastSeen: new Date() }, information.theDevice))
    //     .then(result => {
    //       return this.afs
    //         .collection("devices")
    //         .doc(information.deviceUUID)
    //         .collection("sessions")
    //         .doc(session.sessionUUID)
    //         .collection("actions")
    //         .doc(action)
    //         .collection("items")
    //         .doc(newItemId)
    //         .set({
    //           data: information.data,
    //           sessionUUID: session.sessionUUID,
    //           deviceUUID: information.deviceUUID
    //         });
    //     })
    //     .then(() => {
    //       return this.afs
    //         .collection("interactions")
    //         .doc(action)
    //         .collection("hits")
    //         .add({
    //           data: information.data,
    //           sessionUUID: session.sessionUUID,
    //           deviceUUID: information.deviceUUID
    //         });
    //     })
    //     .then(result => {
    //       this.logger.debug("Logged session action:", newItemId);
    //       resolve("Updated");
    //     });
    // });
  }
}
