import { formatDate } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { firestore } from "firebase/app";

@Pipe({
  name: "seconds"
})
export class SecondsPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(seconds: number, format?: string): string {
    if (seconds !== undefined && seconds !== null) {
      const durationSeconds = moment.duration(seconds, "seconds");
      switch (format) {
        case "hhmmss":
          const asSeconds = durationSeconds.asSeconds();
          const hours = Math.floor(asSeconds / 3600).toString();
          const minutes = Math.floor((asSeconds % 3600) / 60).toString();
          const secondsCalc = Math.floor((asSeconds % 3600) % 60).toString();
          return (
            (String("0").repeat(2) + hours).substr(2 * -1, 2) +
            ":" +
            (String("0").repeat(2) + minutes).substr(2 * -1, 2) +
            ":" +
            (String("0").repeat(2) + secondsCalc).substr(2 * -1, 2)
          );
        case "hours":
          return durationSeconds.asHours().toString();
        case "minutes":
          return durationSeconds.asMinutes().toString();
        case "seconds":
          return durationSeconds.asSeconds().toString();
        default:
          return durationSeconds.humanize();
      }
    } else {
      return "-";
    }
  }
}
