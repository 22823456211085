import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { AngularFirestore } from "@angular/fire/firestore";
import { Device } from "models/device";
import { map } from "rxjs/operators";
import * as moment from "moment";
import { ulid } from "ulid";

@Injectable({
  providedIn: "root"
})
export class StatsService {
  constructor(private logger: NGXLogger, private afs: AngularFirestore) {}

  getParticipant(deviceUUID: string) {
    return this.afs
      .collection("stats")
      .doc("participantStats")
      .collection("data")
      .doc(deviceUUID)
      .valueChanges();
  }

  getPageViewsInTimeline(deviceUUID: string) {
    return new Promise((resolve, reject) => {
      const returnData = { timelines: {}, ulid: ulid() };
      const timelineCollectionRef = this.afs.firestore
        .collection("stats")
        .doc("participantStats")
        .collection("data")
        .doc(deviceUUID)
        .collection("timeline");
      timelineCollectionRef.get().then(timelineQrySnapshot => {
        timelineQrySnapshot.docs.forEach(timelineDoc => {
          returnData["timelines"][timelineDoc.id] = [];
          timelineCollectionRef
            .doc(timelineDoc.id)
            .collection("pageViews")
            .get()
            .then(pageViewQrySnapshot => {
              pageViewQrySnapshot.docs.forEach(pageViewDoc => {
                returnData["timelines"][timelineDoc.id].push(
                  pageViewDoc.data()
                );
              });
            });
        });
        resolve(returnData);
      });
    });
  }

  async getParticipantTimeline(deviceUUID: string) {
    const data = await new Promise((resolve, reject) => {
      console.log("foo");
      this.afs
        .collection("stats")
        .doc("participantStats")
        .collection("data")
        .doc(deviceUUID)
        .collection("timeline")
        // .stateChanges(["added"])
        .snapshotChanges()
        .pipe(
          map(actions => {
            console.log("bar", actions);
            return actions.map(timeline => {
              console.log("timeline id", timeline.payload.doc.id);
              const id = timeline.payload.doc.id;
              const internalData = { id: id, pageViews: {} };
              this.afs
                .collection("stats")
                .doc("participantStats")
                .collection("data")
                .doc(deviceUUID)
                .collection("timeline")
                .doc(id)
                .collection("pageViews")
                .snapshotChanges()
                .subscribe(snapshot => {
                  snapshot.map(pageView => {
                    console.log("pageView", pageView.payload.doc.data());
                    internalData.pageViews[
                      pageView.payload.doc.id
                    ] = pageView.payload.doc.data();
                  });
                });
              resolve(internalData);
            });
          })
        );
    });
    return data;
  }

  getParticipants(
    searchCode: string = "",
    cohortOnly: boolean = false,
    nonCohortOnly: boolean = false
  ) {
    return this.afs
      .collection("stats")
      .doc("participantStats")
      .collection("data", ref => {
        let query:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = ref;
        if (searchCode !== "") {
          query = query
            .where("cohortCode", ">=", searchCode)
            .where("cohortCode", "<", "999999");
        }
        if (cohortOnly) {
          query = query.where("cohortCode", ">", "");
        } else if (nonCohortOnly) {
          query = query.where("cohortCode", "<", "");
        }
        return query;
      })
      .valueChanges();

    // if (searchCode !== "") {
    //   return this.afs
    //     .collection("stats")
    //     .doc("participantStats")
    //     .collection("data", ref =>
    //       ref
    //         .where("cohortCode", ">=", searchCode)
    //         .where("cohortCode", "<", "999999")
    //     )
    //     .valueChanges();
    // } else {
    //   return this.afs
    //     .collection("stats")
    //     .doc("participantStats")
    //     .collection("data")
    //     .valueChanges();
    // }
  }

  getCohortCount(durationInDays: any) {
    const cohortLog = {};
    const nonCohortLog = {};
    let deviceTotal = 0;
    const fromDate = moment().subtract(durationInDays, "days");
    return this.afs
      .collection<Device>("devices", ref =>
        ref.where("lastSeen", ">=", fromDate.toDate())
      )
      .snapshotChanges()
      .pipe(
        map(changes => {
          deviceTotal = changes.length;
          changes.map(a => {
            const devDoc = a.payload.doc.data() as Device;
            devDoc.id = a.payload.doc.id;
            if (devDoc["cohortCode"] !== undefined) {
              if (!cohortLog[devDoc["cohortCode"]]) {
                cohortLog[devDoc["cohortCode"]] = {};
              }
              cohortLog[devDoc["cohortCode"]][devDoc.id] = devDoc.id; // doc.data();
            } else {
              nonCohortLog[devDoc.id] = devDoc.id;
            }
          });
          return {
            cohortLog: cohortLog,
            nonCohortLog: nonCohortLog,
            deviceTotal: deviceTotal
          };
        })
      );
  }
  getEnrolmentData(durationInDays: any, daysOrHours: string = "dates") {
    const fromDate = moment()
      .subtract(durationInDays, "days")
      .toDate();
    return this.afs
      .collection("stats")
      .doc("enrollmentStats")
      .collection(daysOrHours, ref => ref.where("timestamp", ">=", fromDate))
      .valueChanges();
  }
  getCohortSessionCount(durationInDays: any) {
    const fromDate = moment()
      .subtract(durationInDays, "days")
      .toDate();

    return this.afs
      .collection("stats")
      .doc("cohortSessions")
      .collection("data", ref => ref.where("timestamp", ">=", fromDate))
      .valueChanges();
  }
  getNonCohortSessionCount(durationInDays: any) {
    const fromDate = moment()
      .subtract(durationInDays, "days")
      .toDate();
    return this.afs
      .collection("stats")
      .doc("nonCohortSessions")
      .collection("data", ref => ref.where("timestamp", ">=", fromDate))
      .valueChanges();
  }
  getSessionCounts(durationInDays: any) {
    const fromDate = moment()
      .subtract(durationInDays, "days")
      .toDate();

    let cohortSessionCount = 0;
    let nonCohortSessionCount = 0;
    return new Promise((resolve, reject) => {
      return this.afs.firestore
        .collection("stats")
        .doc("cohortSessions")
        .collection("data")
        .where("timestamp", ">=", fromDate)
        .get()
        .then(querySnapshot => {
          cohortSessionCount = querySnapshot.size;
        })
        .then(() => {
          return this.afs.firestore
            .collection("stats")
            .doc("nonCohortSessions")
            .collection("data")
            .where("timestamp", ">=", fromDate)
            .get()
            .then(qrySnap => {
              nonCohortSessionCount = qrySnap.size;
            });
        })
        .then(() => {
          resolve({
            cohortSessionCount: cohortSessionCount,
            nonCohortSessionCount: nonCohortSessionCount
          });
        });
    });
  }
  getResourceStats(statLimit = 5) {
    return this.afs
      .collection("stats")
      .doc("resourceStats")
      .collection("resources", ref =>
        ref.orderBy("hitCountTotal", "desc").limit(statLimit)
      )
      .valueChanges();
  }
  getSessionStats() {
    return this.afs
      .collection("stats")
      .doc("sessionStats")
      .valueChanges();
  }
  getResourceList(searchFilter: string, type: string = "PAGE") {
    if (searchFilter.length) {
      return this.afs
        .collection("resources", ref =>
          ref
            .where("searchfilter", "array-contains", searchFilter)
            .where("type", "==", type)
            .orderBy("order", "asc")
        )
        .valueChanges();
    } else {
      return this.afs
        .collection("resources", ref =>
          ref.where("type", "==", type).orderBy("order", "asc")
        )
        .valueChanges();
    }
  }
}
