import { Injectable, NgZone } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument
} from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { Observable, of, BehaviorSubject } from "rxjs";
import { User } from "models/user";
import * as firebase from "firebase/app";

import { switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  isLoggedIn = false;
  user: Observable<User>;
  redirectUrl: string;
  private invalidAccount: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }
  getInvalidAccount(): Observable<boolean> {
    return this.invalidAccount.asObservable();
  }
  setInvalidAccount(newValue: boolean): void {
    this.invalidAccount.next(newValue);
  }
  googleLogin() {
    this.setInvalidAccount(false);

    // return Observable.of(true).delay(1000).do(val => this.isLoggedIn = true);
    const provider = new firebase.auth.GoogleAuthProvider();
    const x = this.oAuthLogin(provider);
    return x;
  }

  private oAuthLogin(provider) {
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then(credential => {
        this.checkUser(credential.user).then(result => {
          if (result) {
            this.updateUserData(credential.user).then(user => {
              return user.subscribe(userDoc => {
                return this.ngZone.run(() => {
                  this.router.navigate(["/admin"]);
                });
              });
            });
          } else {
            // invalid login
            this.isLoggedIn = false;
            this.user = null;
            this.setInvalidAccount(true);
            this.logout();
          }
        });
      })
      .catch(error => {
        console.error(error);
        console.log("error", error);
        console.log("Has this application been locally built for a different environment");
      });
  }
  private checkUser(user) {
    const userDocRef = this.afs.firestore.collection("users").doc(user.uid);
    const query = this.afs.firestore
      .collection("users")
      .where("email", "==", user.email)
      .limit(1);
    return query.get().then(querySnapshot => {
      if (querySnapshot.size) {
        return userDocRef.get().then(userDoc => {
          return userDoc;
        });
      } else {
        return Promise.resolve(null);
      }
    });
  }
  private updateUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const data = {
      uid: user.uid,
      email: user.email,
      lastSeen: new Date()
    };
    return userRef
      .set(data, { merge: true })
      .then(something => {
        return userRef.get();
      })
      .then(userDoc => {
        return userDoc;
      });
  }

  logout(): void {
    this.afAuth.auth.signOut().then(foo => {
      this.ngZone.run(() => {
        this.router.navigate(["/login"]);
      });
    });
  }
}
