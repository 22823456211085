import { formatDate } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { firestore } from "firebase/app";
import Timestamp = firestore.Timestamp;

@Pipe({
  name: "yyyymmddDate"
})
export class YYYYMMDDDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(yyyymmdd: string, format?: string): string {
    if (yyyymmdd !== undefined && yyyymmdd.length === 8) {
      const yearString = yyyymmdd.substr(0, 4);
      const monthString = yyyymmdd.substr(4, 2);
      const dayString = yyyymmdd.substr(6, 2);
      const date = new Date(yearString + "-" + monthString + "-" + dayString);
      return formatDate(date, format || "dd MMM yyyy", this.locale);
    } else {
      return "";
    }
  }
}
