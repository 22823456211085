import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";

@Component({
  selector: "app-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.css"]
})
export class PageNotFoundComponent implements OnInit {
  err: string;
  slug: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private logger: NGXLogger
  ) {}

  ngOnInit() {
    this.err = this.route.snapshot.queryParams.err || "";
    this.slug = this.route.snapshot.queryParams.slug || "";
  }
}
