import { ulid } from "ulid";

export class CohortCode {
  id: string = ulid();
  cohortCode: string;
  generatedDate: Date = new Date();
  allocatedDate: Date = null;
  allocated = false;
  claimedDate: Date = null;
  claimed = false;
  constructor(cohortCode?: string) {
    if (cohortCode) {
      this.cohortCode = cohortCode;
    }
    return this;
  }
}
